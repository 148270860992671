import React, { Component, PureComponent, useContext, useState } from 'react';
import {NavLink, Link, Switch, Route, Redirect} from 'react-router-dom'
import {RootRouterContext} from '../../index.js'
import OurStoryPage from './OurStoryPage'
import Catering from './Catering'
import Taconight from './Taconight'
import Brunch from './Brunch'
import Order from './Order'
import Menu from './Menu'
import OrderAltadena from './OrderAltadena'
import Headers from './Headers'
import AutoMenu from './AutoMenu'
import CartBar from './CartBar'
import ThemeToggle from './ThemeToggle'
import ThemeContext from '../../Context/ThemeContext'
import LogoHero from './svg/LogoHero'
import Form from './Form'
import './index.scss'
import './ThemeToggle/theme_night.scss'

export const customOrderAppPaths = ["/order", "/menu"]
export const StickyTopBar = () => { return null }

export const locations = [
  {shopId: "myfishstop",     basePath: "/sherman-oaks", cssGlobalPrefix: 'myfishstop'},
  {shopId: "myfishstopnoho",    basePath: "/noho", cssGlobalPrefix: 'myfishstop'},
  // {shopId: "kokorolltorrance",    basePath: "/torrance", cssGlobalPrefix: 'myfishstop'},
  // {shopId: "kokorollhollywood",    basePath: "/hollywood", cssGlobalPrefix: 'myfishstop'},
]

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  {path: "/brunch", label: "Soulfood Sunday", component: Brunch },

  {path: "/hqusers/storeRedirect/:anything", label: "Catering", hideFromNav: true, component: () => <Redirect to="/"></Redirect>},
  // {path: "/taco-night", label: "Event", component: Taconight},
  // {path: "/order-altadena", label: "Menu", component: OrderAltadena},
]

export function SiteNav ({shopId}) {

    const {rootHistory} = useContext(RootRouterContext)


    return (
      <>
      <CartBar shopId={shopId}></CartBar>
      <nav role="navigation" aria-label="navigation">
   
    <div className="pages-nav" >
      <ul aria-label="more links">
        <li><a className="page-link" href="/" onClick={(e) => {
          e.preventDefault()
          rootHistory.push('/')
        }}>All Locations</a></li>
        {pages
          .filter(({hideFromNav}) => !hideFromNav)
          .filter(({path}) => {
            if ((path === '/brunch') && (shopId === 'myfishstopnoho')) {
              return true
            } else {
              return false
            }
          })
          .map(({path, label, external, onClick}) => (
            <li key={path}>
              {external ?
                <a       className="page-link" href={path}>{label}</a> :
                <NavLink className="page-link"
                
                exact to={path}>{label}</NavLink>}
            </li>
        ))}
      </ul>
      {/* <ThemeToggle /> */}
    </div>
  </nav>
  </>
  )
}

export function MultilocationHome () {
  // var preferredTheme = 'dark';
  // try {
  //   preferredTheme = localStorage.getItem('theme') || preferredTheme;
  // } catch (err) { }
  // const [theme, setTheme] = useState(preferredTheme)
  // window.__preferredTheme = theme
  // window.__setPreferredTheme = function (newTheme) {
  //   console.log('setting: ', newTheme)
  //   setTheme(newTheme)
  //   try {
  //     localStorage.setItem('theme', newTheme);
  //   } catch (err) {}
  // }
  // const {themeName: theme} = useContext(ThemeContext)
  const theme = "light"
  const heroLogoUrl = (theme !== 'dark') ?
    "https://afag.imgix.net/tacos-poncitlan-altadena/hero-logo-light.png?h=350" :
    "https://afag.imgix.net/tacos-poncitlan-altadena/hero-logo-dark.png?h=350"

  return(
    <div className={"site-content myfishstop" + " " + theme}>
      <Headers></Headers>
      <div className="home-page-hero" role="banner" aria-labelledby="hero-link">
        <h1>
          <Link to="/" aria-label="Kokoroll Cafe" id="hero-link" className="hero-link">
          
              {/* <LogoHero
                theme={theme}
                ></LogoHero> */}
            <img
              className="hero-logo"
               src={"https://afag.imgix.net/my-fish-stop/logo-1.png?w=300&auto=format"} alt={"My Fish Stop"}/>
          </Link>
        </h1>
      </div>
  
      <Switch>
        <Route path="/order" component={Order} />
        {/* <Route path="/brunch" component={Order} /> */}
        <Route path="/menu" component={Order} />
        <Route path="/catering" component={Catering} />
        <Route path="/taco-night" component={Taconight} />
        <Route path="/" exact component={MultiHeader}></Route>
        <Route path="/hqusers/storeRedirect/:anything" render={() => {
          return(<Redirect to="/"></Redirect>)
        }}></Route>
        <Route path="/brunch" render={() => {
          return(<Redirect to="/noho/brunch"></Redirect>)
        }}></Route>
        {/* <MultiHeader></MultiHeader> */}
      </Switch>
    </div>
  )
}

class MultiHeader extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper" >
        <div className="home-grid">


          <Route path="/www" render={() => (
            <p>hi</p>
          )}>

          </Route>

          <div id="pick-locations" role="main" aria-labelledby="location-header">
            <div className="location-header">
              {/* <div>
                <ThemeToggle></ThemeToggle>
              </div> */}
              <h2 id="location-header">My Fish Stop Locations:</h2>
            
            </div>
            <div className="location-list">{locationsMetadata.map(({label, orderButtonText="Order Pickup / Delivery", address, phone, hours, phoneLink, orderLink, className="", external}) => (
                <div className={"grid-item location " + className}>
                  <div className="location-list-content">
                    <h3 className="title">{label}</h3>
                    <p>{external ? 
                    <a href={orderLink} className="order-location-link">
                    <span className="order-button">Order Pickup / Delivery</span></a> :
                    <a href={orderLink} 
                      aria-label={"Order Pickup or Delivery for " + label + " location"}
                      className="order-location-link">
                      <span className="order-button">{orderButtonText}</span></a>
                      }
                    </p>
                    <div className="address">
                      {address[0]} <br/>
                      {address[1]}
                    </div>
                    <div className="phone">
                      <a
                        className="phone-link"
                        href={`tel:${phoneLink}`}>{phone}</a>
                    </div>
                    <ul className="hours-of-operation">
                      {hours.map((hours) => {
                        return (<li key={hours.day}>
                          <span className="">{hours.day}: {hours.hours}</span>
                          {/* <span className="hours">{hours.hours}</span> */}
                        </li>)
                      })}
                    </ul>
                  </div>
                </div>
              ))}</div>

            <div className="social-media">
              <h2>Follow Us:</h2>
              <div className="link">
              Instagram: <a href="https://www.instagram.com/myfishstop/">@myfishstop</a>
              </div>
              <div className="link">
               Facebook <a href="https://www.facebook.com/myfishstop/">@myfishstop</a>
              </div>
            </div>

            <Form></Form>
            
            </div>

            <div className="video-promo">
              <div className="intro-vid-container">
                <iframe
                  className="intro-vid"
                  src="https://player.vimeo.com/video/707661713?h=582a74683e&amp;autoplay=0&amp;loop=1&amp;autopause=1&amp;muted=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;controls=1"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>

          </div>

      </div>
    );
  }
}


export class Header extends PureComponent {
  render() {
    const now = new Date()
    const day = now.getDay() // Sunday - Saturday : 0 - 6
    const hours = now.getHours() //0 to 23
    const minutes = now.getMinutes() // 0 to 59
    const isSunday = (day === 0)
    const shopId = this.props.shopId
    return (
      <div className="location-page">
        <div className="top-banner" style={{
          backgroundImage: "url(https://afag.imgix.net/my-fish-stop/banner.jpg?w=1200&auto=format)"
        }}>
        <div role="main" aria-label="location information" className="info header">
          {locationsMetadata
            .filter((l) => l.id === this.props.shopId)
            .slice(0,1)
            .map(({label, address, phone, hours, special, phoneLink, className=""}) => (
              <div className={"location"}>
                
                <div className="content">
                  {/* <h2>Kokoroll {label}</h2> */}
                  {address && <div aria-label="location address" className="address">
                    <p>
                      Address: <br/>
                      {address[0]} <br/>
                      {address[1]}
                    </p>
                  </div>}
                  {phone && <div
                   aria-label="location phone number"
                   className="phone">
                    Phone: <a
                      className="phone-link"
                      href={`tel:${phoneLink}`}>{phone}</a>
                  </div>}
                  {hours && <p 
                   aria-label="location hours of operation"
                  className="hours-of-operation">
                    {hours.map((hours) => {
                      return (<div 
                      
                      key={hours.day}>
                
                          <span className="">{hours.day}: {hours.hours}</span>
                          {/* <span className="hours">{hours.hours}</span> */}
                    
                      </div>)
                    })}
                  </p>}
                  <div 
                   aria-label="location online services"
                  className="order-action">
                    <a href="#order" className="order-button">Order Pickup / Delivery ↓</a>
                  </div>
                </div>
              </div>
          ))}




          </div>
        </div>

        {(this.props.shopId === 'myfishstopnoho') && isSunday && <AutoMenu 
          
          tagsToShow={['nohobrunch']} 
          disableOrder={!isSunday}
        />}

        {(this.props.shopId === 'myfishstopnoho') && !isSunday && <AutoMenu 
        disableOrder={isSunday}
        subMenuFilter={subMenuFilter.bind(this, this.props.shopId)} 
        width={500}></AutoMenu>}


      {(this.props.shopId === 'myfishstop') && <AutoMenu 
        disableOrder={isSunday}
        subMenuFilter={subMenuFilter.bind(this, this.props.shopId)} 
        width={500}></AutoMenu>}

    {(this.props.shopId === 'myfishstopnoho') && !isSunday && <AutoMenu 
          
          tagsToShow={['nohobrunch']} 
          disableOrder={!isSunday}
        />}
        
      </div>
    );
  }
}



let locationsMetadata = [
  {
    id: 'myfishstop',
    label: "Sherman Oaks",
    address: ["14843 1/2 Burbank Blvd", "Sherman Oaks"],
    phone: "888-469-9980 (#1)",
    phoneLink: "+18884699980",
    orderLink: "/sherman-oaks",
    orderButtonText: "Order Pickup",
    hours: [
      { day: 'Mon-Fri', hours: '10am-9pm' },
      { day: 'Sat', hours: '1pm-7pm' },
      { day: 'Sunday', hours: 'closed' },
    ]},

  {
    id: 'myfishstopnoho',
    label: "Noho",
    className: 'night',
    address: ["11046 Magnolia Blvd", "North Hollywood"],
    phone: "(747) 205-2024",
    phoneLink: "+17472052024",
    orderLink: "/noho",
    hours: [
      { day: 'Mon-Fri', hours: '10am-9pm' },
      { day: 'Sat', hours: '1pm-7pm' },
      { day: 'Sunday', hours: 'closed' },
    ]},

    // {
    //   id: 'myfishstopnoho',
    //   label: "Soul Food Sundays",
    //   className: 'night',
    //   address: ["11046 Magnolia Blvd", "North Hollywood"],
    //   phone: "(747) 205-2024",
    //   phoneLink: "+17472052024",
    //   orderLink: "/noho/brunch",
    //   hours: [       
    //     { day: 'Soul Food Sun', hours: '11am-4pm' },
    //   ]},

]

export function subMenuFilter (shopId, subMenu) {
  const tag = subMenu.tag
  // console.log( 'HAHA', tag)
  if (!tag) {
    return true
  }

  switch (shopId) {
    case 'myfishstop':
      if (tag.indexOf('oaks-loc') !== -1) {
        return true
      } else {
        return false
      }
      break;
    case 'myfishstopnoho':
      if (tag.indexOf('noho-loc') !== -1) {
        return true
      } else {
        return false
      }
      break;
    default:
      return false
      break;
  }

  return true
}
