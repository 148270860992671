import {sortBy} from 'lodash'
export default function calcPromoDiscount (args) {
  // console.log({shopId, products})
  try {
    const {
      shopId, cartType,
      products, promoCode='', totalPrice, taxTotal
    } = args

    let totalDiscount = 0


    if (shopId === 'lancers') {
      // if (['7SH9SAG8', '7sh9sag8'].indexOf((promoCode || '').toLowerCase()) !== -1) {
      //   totalDiscount += Math.min(100, (totalPrice+taxTotal))
      // }
      // if (['AX89FHS3HO', 'ax89fhs3ho'].indexOf((promoCode || '').toLowerCase()) !== -1) {
      //   totalDiscount += Math.min(200, (totalPrice+taxTotal))
      // }
    }
    if (shopId === 'pablitostacosburbank') {
      // if (['A6H87H', 'a6h87h'].indexOf((promoCode || '').toLowerCase()) !== -1) {
      //   totalDiscount += Math.min(50, (totalPrice+taxTotal))
      // }
      
    }
    if (shopId === 'pablitosworld') {
      const priceList = []
      products.forEach(({tags, sub, price, quantity}) => {
        console.log(tags)
        if (tags && tags.indexOf('bogo-promo') !== -1) { // is bogo
          for (let step = 0; step < quantity; step++) {
            priceList.push(Number(price))
          }
        }
       
      })
      const sorted = sortBy(priceList)
      if (sorted.length > 1) {
        totalDiscount += sorted[0]
      }
      
    }
    if (shopId === 'ksthollywood') {
      const priceList = []
      products.forEach(({tags, sub, price, quantity}) => {
        console.log(tags)
        if (tags && tags.indexOf('bogo-promo') !== -1) { // is bogo
          for (let step = 0; step < quantity; step++) {
            priceList.push(Number(price))
          }
        }
       
      })
      const sorted = sortBy(priceList)
      console.log('kst price list:', sorted)
      if (sorted.length > 0) {
        const discountCount = Math.floor(sorted.length / 2)
        console.log({discountCount})
        const pricesToDiscount = sorted.slice(0,discountCount)
        console.log('prices', pricesToDiscount)
        pricesToDiscount.forEach((price) => {
          totalDiscount += (price / 2)
        })
      }
      

      
    }


  
    if (shopId === 'kluckinsd') {
      const now = new Date()
      const day = now.getDay() // Sunday - Saturday : 0 - 6
      const hours = now.getHours() //0 to 23
      const minutes = now.getMinutes() // 0 to 59
      console.log(promoCode, day, hours)
      if (['kluckin15'].indexOf((promoCode || '').toLowerCase()) !== -1) {
       
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
       
      }
      if (['getklucked'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        if ([0,1,21,22,23].indexOf(hours) !== -1) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
      }
      if (['kluckinlunch'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        if ([12,13].indexOf(hours) !== -1) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
        if ((hours === 11) && (minutes > 29)) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
      }
    }

    if (shopId === 'kokorollpa') {
      if (totalPrice > 30) {
        totalDiscount += 5
      }
    }
    if (shopId === 'pablitostacosnorth') {
      if (['fathersday'].indexOf((promoCode || '').toLowerCase()) !== -1) {
       
        totalDiscount += Math.round(totalPrice * 0.2 * 100) / 100
     
      }
    }




    // if (shopId === 'pablitostacosburbank') {
    //   if (['janequengua3'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.min(9, (totalPrice + taxTotal))
    //   }
    // // }
   
    // if (shopId === 'sushidon') {
    //   // cinco
    //   let numTrays = 0
    //   products.forEach(({name, sub, price, quantity=1}) => {
    //     console.log(name, sub)
    //     if (name && name.en === 'Rainbow Tray ') { // is street tacos
    //       numTrays += quantity
    //     } 
    //     if (name && name.en === ('Rock & Roll  platter')) { // is street tacos
    //       numTrays += quantity
    //     } 
    //   })
    //   console.log('numTrays', numTrays)

    //   if (numTrays === 1) {
    //     totalDiscount = totalDiscount + Math.round(40 * 0.05 * 100) / 100
    //   }
    //   if (numTrays === 2) {
    //     totalDiscount = totalDiscount + Math.round(80 * 0.10 * 100) / 100
    //   }
    //   if (numTrays > 2) {
    //     totalDiscount = totalDiscount + Math.round(40 * numTrays * 0.15 * 100) / 100
    //   }
      
      
      
    // }

 
    if (shopId === 'pablitostacosnoho') {
      let hasCafe = false
      if ((totalPrice > 20)) {
        products.forEach(({name, sub, price, quantity=1}) => {
          console.log(name, sub)
          if (name && name.en === 'Cafe De Olla') { // is street tacos
            hasCafe = true
          } 
        })
      }
      if (hasCafe) {
        totalDiscount = 5
      }
    }
    // if (shopId === 'cravesunset') {
    //   if (['ida40'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.round(totalPrice * 0.1 * 100) / 100
    //   }
    // }
   
    // if (shopId === 'muiishi') {
    //   let hasChicken = false
    //   let totalPrice = 0
    //   products.forEach(({name, sub, price, quantity=1}) => {
    //     if (name && name.en.indexOf('Gyoza') !== -1) { // is wine
    //       hasChicken = true
    //     } else {
    //       totalPrice = price * quantity + totalPrice
    //     }
    //   })
      

    //   if ((totalPrice > 10) && (hasChicken)) {
    //     totalDiscount += Number(5.99)
    //   }

    // }


    return Math.round(totalDiscount * 100) / 100
  } catch (e) {
    return 0
  }


}
