import React from 'react'
import './catering.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="catering-page catering page">
      <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/fetch/c_fill,w_1200/https://dl.airtable.com/MxH1Ew3WQiSfgs6AvoQX_IMG_0477.jpg)"}}>
        <h1>Catering</h1>
      </div>

      <header>
          {/* <h1 className="page-title">
            Catering<br />
            by Tacos Poncitlan
          </h1> */}
          {/* <p>Call us or <a href="https://calendly.com/tacosponcitlan/60min/10-30-2017">schedule a food tasting</a> for your event.</p> */}
          <p>Call us to schedule a food tasting for your event.</p>
          <p>Email: catering@tacosponcitlan.com<br/>
            Phone: (626)396-9150
        </p>

        {/* <div className="tasting-link-wrap">
          <a href="https://calendly.com/tacosponcitlan/60min/10-30-2017">Schedule a Food Tasting</a>
        </div> */}

        </header>
        <section className="content">




          <article className="option">
            <header>
               <h2 className="title">$11.50 Per Person<br />Taco Bar</h2>
             </header> <p>4 tacos per person with rice and beans</p> <ul className="features-list"> <li>Individually wrapped soft tacos</li> <li>Choice of 2 Filling Meats
                {/* <PhotosGrid category="Fillings"></PhotosGrid> */}
              </li>
              <li>Toppings: onion, cilantro, radishes, and limes</li>
              <li>2 salsas: red/hot and green/mild</li>
              <li>Rice & Pinto Beans</li>
              <li>
                Chips and Salsa
                {/* <PhotosGrid category="Extras" items={["Chips & Salsa"]}></PhotosGrid> */}
              </li>
            </ul>




          </article>

          <article className="option">
            <header>
               <h2 className="title">$14.50 Per Person<br />Deluxe Taco Bar</h2>
             </header> <p>Everything in Taco Bar plus:</p> <ul className="features-list"> <li>1 Additional filling (3 Fillings in total)</li> <li>Extra toppings: cheese & sour cream</li>
              <li>Chips and Guacamole
                {/* <PhotosGrid category="Extras" items={["Chips & Guacamole"]}></PhotosGrid> */}
              </li>
              <li>
                Drink produced in-house: Agua Fresca
                {/* <PhotosGrid category="Aguas Frescas 💦"></PhotosGrid> */}
              </li>
            </ul>



          </article>
          <article className="option">
            <header>
               <h2 className="title"> $7.5/person <small>(minimum: $750)</small><br /> Ttraditional Street Style Tacos<br /> </h2>
             </header> <p>Comes with your choice of (4) meats and all standard taco toppings.</p> <ul className="features-list">
               {/* <li>Tacos, Rice & Pinto Beans and a beverage</li>  */}
               {/* <li> Choose from 4 taco-cart meats: <PhotosGrid category="Street Tacos"></PhotosGrid>
              </li> */}
            </ul>

          </article>

          <article className="option">
            <header><h2 className="title"> $12.50/person<br/> Double Entreé </h2></header> <p>Traditional Mexican Meal</p>
            <ul className="features-list"> <li>Pick one from plated meat, enchiladas, flautas or shrimp.</li> <li> plated entree meat options:
              {/* <PhotosGrid category="Fillings" items={["Birria", "Buche", "Carnitas", "Chicharrón Verde", "Chicharrón Rojos", "Beef Fajitas", "Chicken Fajitas"]}></PhotosGrid> */}
              </li>
              <li>enchiladas & flautas options contain 4 pieces</li>
              <li>Shrimp options: 5 pieces of shrimp in red sauce, hot sauce or garlic</li>
              <li>Topped with onion, cilantro & limes</li>
              <li>Rice & Pinto Bean sides</li>
              <li>Pre-heated corn tortillas or Chips.</li>
            </ul>
          </article>

          <article className="option">
            <header>
               <h2 className="title"> $15.50/person<br/> Double Entreé Deluxe </h2>
             </header> <p>Everything in Double Entree plus:</p> <ul className="features-list"> <li>Pick two instead of one from plated meat, enchiladas, flautas or shrimp.</li> <li>enchiladas & flautas options contain 6 pieces</li> <li> additional entree meat, making it two entree meats in total.
              </li>
              <li>Optionally, replace meat with 6 Enchilada or Flautas</li>
              <li>Shrimp options: 12 pieces of shrimp in red sauce, hot sauce or garlic</li>
              <li>Topped with onion, cilantro, radishes, cheese, sour cream & limes</li>
              <li>Rice & Pinto Bean sides</li>
              <li>Chips and Guacamole
                {/* <PhotosGrid category="Extras" items={["Chips & Guacamole"]}></PhotosGrid> */}
              </li>
              <li>
                Drink produced in-house: Agua Fresca
                {/* <PhotosGrid category="Aguas Frescas 💦"></PhotosGrid> */}
              </li>
            </ul>
          </article>

          <article className="option">
            <header><h2 className="title"> Taco Tray<br/> $74 for 45 tacos<br/> $98 for 60 tacos </h2></header> <p>choice of (2) meat fillings, two salsas, onion, cilantro, and lemon</p> </article> <article className="option"> <header><h2 className="title"> Flautas/Taquito Dorado  Tray<br/> $53 for 30<br/> $105 for 60 </h2></header> <p>served with guacamole, lettuce, and sour cream</p> </article> <article className="option"> <header><h2 className="title"> Enchilada Platter<br/> $60 for 30<br/> $120 for 60 </h2></header> <p>choice of Red or Green Sauce</p> </article> <article className="option"> <header><h2 className="title"> Torta Tray<br/> $38 for 5 tortas<br/> $75 for 10 tortas </h2></header> <p>upgrade to Torta Achaparadas for an additional $1 per torta/</p> <ul className="features-list"> <li>choice of Asada Steak, Adobada, Carnitas, Chicken </li> <li>served with grilled onions, cilantro, three slices of avocado</li> <li>all tortas are cut in half.</li> </ul>
          </article>
          <article className="option">
            <header>
               <h2 className="title"> Burrito Tray<br/> $113 for 15 Burritos<br/> </h2>
             </header> <ul className="features-list"> <li>choice of 11 fillings listed on our menu</li> <li>Shrimp upgrade $3 per burrito</li> <li>served with onion, cilantro, choice of hot or mild salsa</li> <li>includes chips and 3oz salsa per burrito </li> </ul>
          </article>
          <article className="option">
            <header><h2 className="title"> Sides </h2></header> <ul className="features-list"> <li>Rice for 5 (32oz) $10</li> <li>Pinto Beans for 5 (32oz) $10</li> <li>Chips & Salsa for 5 $12</li> <li>Guacamole for 10 $18</li>
            </ul>
          </article>
          <article className="option">
            <header>
               <h2 className="title"> Dessert </h2>
             </header> <ul className="features-list"> <li>Flan $25</li> <li>Conchita Box (an assortment of 15 mini conchitas.) $8</li> </ul>
          </article>

          <p>You can place an order with us either via phone/email</p>
          <p>Email: catering@tacosponcitlan.com</p>
          <p>Phone: (626)396-9150</p>

        </section>
    </div>
  )
}
