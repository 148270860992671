import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isSunday = (day === 0)
  return(
    <div className="taconight-page">
      <div className="top-banner" 
        style={{
          minHeight: "40vh", 
          backgroundImage: "url(https://afag.imgix.net/my-fish-stop/soul-food-sundays.webp?w=1200&auto=format)"
          
          }}>
        {/* <div className="text with-background">
          <h1>Taco Night</h1>
          <p><strong>Thursday & Friday night</strong> 7pm - 10pm</p>
          <p>@ our Altadena Location only</p>
        </div> */}
      </div>

      

      <AutoMenu 
        hideNav={true}
        disableOrder={!isSunday}
        tagsToShow={['nohobrunch']} />
{/* 
      <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div> */}
    </div>
  )
}
